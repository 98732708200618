import React, { useEffect, useState } from "react";
import "./index.scss";
import Accordion from "../components/accordion";
import File from "../assets/Bestellschein_Deutschlandticket_beschreibbar.pdf";
import Formular from "../assets/Firmenticket_Formular.pdf";
import DownloadApple from "../assets/download-icon-apple.png";
import DownloadGoogle from "../assets/download-icon-google.png";
import JobInfo from "../assets/Deutschlandticket_Job_Informationen.pdf";
import schulPDF from "../assets/Deutschlandticket_Schule_Informationen.pdf";
import ticketLogo from "../assets/ticketlogo.webp";
import iconMittle from "../assets/icon-mittel.png";
import dtBigLogo from "../assets/jetzt_Kaufen_3.svg";
import dtSchule from "../assets/DticketSchule.png";
import FormularLogo from "../assets/formularlogo.png";
import bestellscheinschule from "../assets/Bestellschein_Deutschlandticket_Schule_beschreibbar.pdf";
import Bus from "../assets/bus.svg";
import Badge from "../assets/gewinn_badge.svg";
import { Link } from "react-router-dom";
import MainBlock from "../components/MainBlock";
import DashboardData from "./Dashboard.json";

export default function Dashboard() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    return (
        <>
            <div className='header'>
                <div
                    style={{
                        maxWidth: 1100,
                        margin: "0 auto",
                        display: "flex",
                        height: "80%",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <img className='dtbiglogo' src={dtBigLogo}></img>
                    {false && <img className='badge' src={Badge}></img>}

                    <img className='buslogo' src={Bus}></img>
                    <div className='info'>
                        Das Deutschlandticket. Bundesweite Mobilität ab
                        01.01.2025 für nur 58,- € im Monat. Flexibel kündbar.
                    </div>
                </div>
            </div>
            {DashboardData.dashboard.map((singlebox, index) => (
                <MainBlock
                    data={singlebox}
                    key={"mainBlock" + index}
                    id={index}
                />
            ))}

            <section className='accordion' id='faq'>
                <div className='accordion-title'>
                    Häufig gestellte Fragen zum Deutschlandticket
                </div>
                <div className='accordion-box'>
                    <Accordion
                        question={"Was ist das Deutschlandticket? "}
                        answer={
                            "Das Deutschlandticket ist ein bundesweit gültiges Ticket, das ab dem 01.01.2025 zu einem Einführungspreis von 58 Euro pro Monat die Nutzung des deutschen öffentlichen Nahverkehrs ermöglicht. "
                        }></Accordion>
                    <Accordion
                        question={"Wie lange ist das Deutschlandticket gültig?"}
                        answer={
                            "Das Deutschlandticket gilt für einen Monat. Liegt keine Kündigung vor, verlängert sich das Abonnement automatisch um jeweils einen weiteren Monat. "
                        }></Accordion>
                    <Accordion
                        question={
                            "Wann kann ich das Deutschlandticket nutzen?  "
                        }
                        answer={
                            "Während der Dauer des Abonnements kann das Deutschlandticket für beliebig viele Fahrten und rund um die Uhr genutzt werden. "
                        }></Accordion>

                    <Accordion
                        question={"Wo kann ich das Deutschlandticket nutzen?  "}
                        answer={
                            "Das Deutschlandticket ist – wie der Name schon sagt – in ganz Deutschland für die Nutzung des öffentlichen Nahverkehrs (2. Klasse) gültig. "
                        }></Accordion>

                    <Accordion
                        question={
                            "Gibt es das Deutschlandticket auch als Jobticket?  "
                        }
                        answer={
                            'Ja, das neue "Deutschlandticket Job" ist äußerst attraktiv für Unternehmen und Mitarbeitende. Alle Informationen findest du im Bereich "Deutschlandticket Job für Firmenkunden" auf dieser Seite.'
                        }></Accordion>

                    <Accordion
                        question={"Kann ich das Deutschlandticket bestellen? "}
                        answer={
                            "Der Verkehrsbetrieb der Stadtwerke Solingen ermöglicht die Bestellung per PDF, digital oder persönlich in den Service-Centern ÖPNV."
                        }></Accordion>

                    <Accordion
                        question={
                            "Kann ich mit dem DeutschlandTicket auch in der 1. Klasse fahren?  "
                        }
                        answer={
                            "Für die Nutzung der 1. Klasse bietet der VRR das 1. Klasse Monatsticket und das 1. Klasse Aboticket für jeweils 46 Euro pro Monat an. Es ermöglicht die Nutzung der 1. Klasse in Nahverkehrszügen im VRR. Es gilt in Verbindung mit dem DeutschlandTicket oder einem anderen Aboticket. \n  Seit dem 01.07.2023 gibt es 1. Klasse Abotickets auch für ganz NRW für 69 Euro pro Monat. Bei Fahrten über die Landesgrenzen hinaus ist die Nutzung der 1. Klasse nicht möglich. "
                        }></Accordion>

                    <Accordion
                        question={
                            "In welcher Ticketform kann ich das Deutschlandticket bestellen? "
                        }
                        answer={
                            "Bei Bestellung des Deutschlandtickets kannst du wählen, ob du das Ticket digital nutzen möchtest oder eine Chipkarte zugesendet bekommen möchtest. Je nachdem unterscheidet sich auch der Bestellungsweg (Online-Registrierung oder PDF ausfüllen).  "
                        }></Accordion>
                    <Accordion
                        question={
                            "Was kostet es, wenn ich mein Fahrrad mitnehmen möchte?  "
                        }
                        answer={
                            "Die Fahrradmitnahme im VRR Verbundraum ist über ein Monatsticket für 31,70 € möglich, das zusätzlich zum Deutschlandticket gekauft werden kann. Seit dem 01.07.2023 gibt es Fahrrad Abotickets auch für ganz NRW für 42,50 Euro pro Monat. Für die gelegentliche Fahrradmitnahme empfehlen wir das FahrradTicket des VRR für nur 4,20 Euro, mit dem ein Rad 24 Stunden beliebig oft verbundweit in Bus und Bahn mitgenommen werden kann. "
                        }></Accordion>
                    <Accordion
                        question={
                            "Ist die Mitnahme von Haustieren im Deutschlandticket integriert?"
                        }
                        answer={
                            "Die Verkehrsverbünde VRR und VRS erlauben die Mitnahme eines Haustiers ohne Zusatzticket. Bei der Weiterfahrt in andere Verkehrsverbünde bitten wir darum, sich vorher über die dortigen Regelungen zu informieren. "
                        }></Accordion>
                    <Accordion
                        question={
                            "Gibt es das Deutschlandticket auch für Schüler?"
                        }
                        answer={
                            "In Solingen wurde das Deutschlandticket Schule zum 1. August 2023 offiziell eingeführt. Ab 01.01.2025 bietet der Schulträger der Stadt Solingen seinen Schüler*innen das Deutschlandticket Schule zum vergünstigten Preis von 38,- € monatlich an."
                        }></Accordion>
                    <Accordion
                        question={
                            "Gibt es das Deutschlandticket auch für Studierende?"
                        }
                        answer={`Bund und Länder haben 2024 ein günstiges Deutschlandticket für Studierende eingeführt. Seit dem Frühjahr ist es als "Deutschland Semesterticket" Bestandteil des Studentenausweises. Für Studierende an den Fachhochschulen im VRR gibt es das erweitere Semesterticket. Schon jetzt ist klar: Ab dem Sommersemester 2025, also ab April, wird das Deutschlandticket auch für Studenten teurer. Fünf Euro mehr kostet es, weil Studenten nur 60 Prozent des Normalpreises bezahlen müssen.`}></Accordion>
                </div>
            </section>
        </>
    );
}
