import React, { useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { useFormspark } from "@formspark/use-formspark";
import { useParams } from "react-router-dom";
import axios from "axios";
export default function Page() {
    const { slug } = useParams();
    const [data, setData] = useState(null);
    useEffect(() => {
        async function getData(slug) {
            try {
                let { data } = await axios.get(
                    "https://gut-vorankommen.de/wp-json/dticket/v1/" + slug
                );

                setData(data);
            } catch (e) {
                setData({ title: "Die Seite wurde nicht gefunden." });
            }
        }
        getData(slug);
    }, [slug]);
    if (data) {
        return (
            <>
                {data?.featured_image || data?.logo ? (
                    <>
                        <div
                            className='header singlepage small'
                            style={{
                                backgroundImage: `url(${data.featured_image})`,
                            }}>
                            {data.logo && (
                                <img src={data.logo} alt={data.title} />
                            )}
                        </div>
                        <div className='content'>
                            <div className='inner'>
                                <h1>{data.title}</h1>

                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: data.content,
                                    }}></div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='header small'>
                            <h2 className='header-title-single'>
                                {data.title}
                            </h2>
                        </div>
                        <div className='content'>
                            <div
                                className='innner'
                                dangerouslySetInnerHTML={{
                                    __html: data.content,
                                }}></div>
                        </div>
                    </>
                )}
            </>
        );
    } else {
        return <div></div>;
    }
}
